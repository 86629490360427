<template>
	<div>
		<s-crud
			add
			@addEvent="add()"
			edit
			@EditEvent="EditEvent($event)"
			title="Contacto Cliente"
			:config="config"
			ref="crudcustomercontact"
		>
		</s-crud>

		<v-dialog
			v-model="dialogContact"
			fullscreen
			hide-overlay
			persistent
		>
			<v-card>
				<customer-contact-add :CustomerContact="CustomerContact" :isEdit="isEdit" @closeCustomerContactAdd="closeCustomerContactAdd"></customer-contact-add>
			</v-card>
			
		</v-dialog>
	</div>
</template>

<script>
	import _sExtCustomerContactService from "@/services/ExternalTrade/ExtCustomerContactService.js";
	import SselectClientSap from '@/components/Sale/SselectClientSap.vue';
	import CustomerContactAdd from "./CustomerContactAdd.vue"

	export default {
		components: { 
			SselectClientSap,
			CustomerContactAdd
		},

		data() {
			return {
				config: {
					model: {
						CctID : "ID"
					},
					service: _sExtCustomerContactService,
					headers: [
						{ text: "ID", value: "CctID" },
						{ text: "Cliente", value: "CardName" },
						{ text: "Nombre Contacto", value: "CctContactInformationName" }
					]
				},
				dialogContact: false,
				CustomerContact: null,
				isEdit: false,
			}
		},

		methods: {
			add() {
				this.dialogContact = true;
				this.CustomerContact = null;
			},

			closeCustomerContactAdd()
			{
				this.$refs.crudcustomercontact.refresh();
				this.dialogContact = false;
				this.isEdit = false;
			},

			EditEvent(item)
			{
				this.dialogContact = true;
				this.CustomerContact = item[0];
				this.isEdit = true;
			}
		},
	}
</script>
<template>
	<div style="padding: 20px 15px">
		<v-row>
			<s-toolbar
				label="Contacto Cliente"
				color="light-blue darken-3"
				dark
				close
				@close="closeCustomerContactAdd()"
				save
				@save="save()"
			>
			</s-toolbar>
		</v-row>
		<v-row>
			<v-col cols="12" lg="6" md="6" sm="12">
				<v-card class="pb-3">
					<s-toolbar 
						label="Información General"
						color="#E0F7F2"
					></s-toolbar>
					<v-container>
						<v-row>
							<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
								<sselect-client-sap 
									label="Cliente"
									return-object
									v-model="objCustomer"
								></sselect-client-sap>
							</v-col>
							<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
								<s-text
									label="Nombre Compañia"
									v-model="CctCompanyName"
								>
								</s-text>
							</v-col>
							<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
								<s-text
									label="Dirección"
									v-model="customerClient.CctCompanyAddress"
								>
								</s-text>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
								<s-text
									label="TAX ID/ VAT number / NIF"
									v-model="customerClient.CctCompanyTax"
								>
								</s-text>
							</v-col>
							<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
								<s-text
									label="Telefono"
									v-model="customerClient.CctCompanyPhone"
								>
								</s-text>
							</v-col>
							<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
								<s-text
									label="Fax"
									v-model="customerClient.CctCompanyFax"
								>
								</s-text>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
								<s-text
									label="Número EORI"
									v-model="customerClient.CctCompanyEori"
								>
								</s-text>
							</v-col>
							<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
								<s-load
									label="ISF (Solo clientes USA)x"
									v-model="fileIsf"
								>
								</s-load>
							</v-col>
						</v-row>
					</v-container>
				</v-card>
			</v-col>
			<v-col cols="12" lg="6" md="6" sm="12">
				<v-card class="pb-3">
					<s-toolbar 
						label="Información Conocimiento Embarque - Consignatario"
						color="#E0F7F2"
					></s-toolbar>
					<v-container>
						<v-row>
							<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
								<s-text
									label="Nombre"
									v-model="customerClient.CctConsigneeName"
								>
								</s-text>
							</v-col>
							<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
								<s-text
									label="Dirección"
									v-model="customerClient.CctConsigneeAddress"
								>
								</s-text>
							</v-col>
							<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
								<s-text
									label="TAX ID/ VAT number / NIF"
									v-model="customerClient.CctConsigneeTax"
								>
								</s-text>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
								<s-text
									label="Teléfono"
									v-model="customerClient.CctConsigneePhone"
								>
								</s-text>
							</v-col>
							<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
								<s-text
									label="Fax"
									v-model="customerClient.CctConsigneeFax"
								>
								</s-text>
							</v-col>
							<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
								<s-text
									label="Nombre Contacto"
									v-model="customerClient.CctConsigneeNameContact"
								>
								</s-text>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
								<s-text
									label="Email Contacto"
									v-model="customerClient.CctConsigneeEmailContact"
								>
								</s-text>
							</v-col>
						</v-row>
					</v-container>
				</v-card>
			</v-col>
		</v-row>

		<v-row class="mt-0">
			<v-col cols="12" lg="6" md="6" sm="12">
				<v-card class="pb-3 mb-2">
					<s-toolbar 
						label="Información Contacto"
						color="#E0F7F2"
					></s-toolbar>
					<v-container>
						<v-row>
							<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
								<s-select-definition
									:def="1317"
									label="Posición"
									v-model="customerClient.TypeContactInformationPosition"
								/>
							</v-col>
							<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
								<s-text
									label="Nombre"
									v-model="customerClient.CctContactInformationName"
								>
								</s-text>
							</v-col>
							<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
								<s-text
									label="Teléfono"
									v-model="customerClient.CctContactInformationPhone"
								>
								</s-text>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
								<s-text
									label="Email"
									v-model="customerClient.CctContactInformationEmail"
								>
								</s-text>
							</v-col>
						</v-row>
					</v-container>
				</v-card>
			</v-col>
			<v-col cols="12" lg="6" md="6" sm="12">
				<v-card class="pb-3">
					<s-toolbar 
						label="Información Documentaria"
						color="#E0F7F2"
					></s-toolbar>
					<v-container>
						<v-row>
							<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
								<s-text
									label="Contacto Principal"
									v-model="customerClient.CctDocumentaryInformationMainContact"
								>
								</s-text>
							</v-col>
							<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
								<s-text
									label="Dirección"
									v-model="customerClient.CctDocumentaryInformationAddress"
								>
								</s-text>
							</v-col>
							<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
								<s-text
									label="TAX ID/ VAT number / NIF"
									v-model="customerClient.CctDocumentaryInformationTax"
								>
								</s-text>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
								<s-text
									label="Teléfono"
									v-model="customerClient.CctDocumentaryInformationPhone"
								>
								</s-text>
							</v-col>
							<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
								<s-text
									label="Email"
									v-model="customerClient.CctDocumentaryInformationEmail"
								>
								</s-text>
							</v-col>
							<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
								<v-btn
									class="mt-3"
									color="success"
									block
									@click="dialogOtherContact = true"
								>
									Otros Contactos
								</v-btn>
							</v-col>
						</v-row>
					</v-container>
				</v-card>
			</v-col>
		</v-row>

		<v-row class="mt-0">
			<v-col cols="12" lg="12" md="12" sm="12">
				<v-card class="pb-3">
					<s-toolbar 
						add
						@add="dialogNotifier = true"
						label="Notificantes"
						color="#E0F7F2"
					></s-toolbar>
					<v-data-table
						:headers="headers"
						:items="itemsNotification"
					>
						<template v-slot:item.accion="{ item }">
							<v-btn
								@click="deleteItemNotification(item)"
								block
								x-small
								color="error"
							>
								<v-icon x-small>fas fa-trash-alt</v-icon>
							</v-btn>
						</template>
					</v-data-table>
				</v-card>
			</v-col>
		</v-row>

		<v-dialog
			width="700px"
			persistent
			v-model="dialogNotifier"
		>
			<v-card height="430px">
				<s-toolbar
					close
					dark
					save
					@save="addItemNotification"
					color="light-blue darken-3"
					@close="dialogNotifier = false"
					label="Registro de notificante"
				>
				</s-toolbar>
				<v-container>
					<v-row>
						<v-col class="s-col-form" cols="12" lg="4" md="4" sm="12" xs="12">
							<s-text
								label="Puerto Destino"
								v-model="itemNotification.CcnDestinationPort"
							>
							</s-text>
						</v-col>
						<v-col class="s-col-form" cols="12" lg="4" md="4" sm="12" xs="12">
							<s-text
								label="Nombre Notificante"
								v-model="itemNotification.CcnName"
							>
							</s-text>
						</v-col>
						<v-col class="s-col-form" cols="12" lg="4" md="4" sm="12" xs="12">
							<s-text
								label="Dirección Notificante"
								v-model="itemNotification.CcnAddress"
							>
							</s-text>
						</v-col>
					</v-row>
					<v-row>
						<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
							<s-text
								label="TAX ID/ VAT number / NIF"
								v-model="itemNotification.CcnTax"
							>
							</s-text>
						</v-col>
						<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
							<s-text
								label="Teléfono"
								v-model="itemNotification.CcnPhone"
							>
							</s-text>
						</v-col>
						<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
							<s-text
								label="Fax"
								v-model="itemNotification.CcnFax"
							>
							</s-text>
						</v-col>
					</v-row>
					<v-row>
						<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
							<s-text
								label="Nombre Contacto"
								v-model="itemNotification.CcnNameContact"
							>
							</s-text>
						</v-col>
						<v-col class="s-col-form" cols="12" lg="4" md="6" sm="12" xs="12">
							<s-text
								label="Email Contacto"
								v-model="itemNotification.CcnEmailContact"
							>
							</s-text>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>

		<v-dialog
			width="700px"
			persistent
			v-model="dialogOtherContact"
		>
			<v-card height="400px">
				<s-toolbar
					close
					dark
					add
					@add="addItemsOtherContact()"
					color="light-blue darken-3"
					@close="dialogOtherContact = false"
					label="Registro de otros contactos"
				>
				</s-toolbar>
				<v-container>
					<v-row>
						<v-col class="s-col-form" cols="12" lg="12" md="12" sm="12" xs="12">
							<s-text
								label="Email"
								v-model="emailOtherContact"
								@keyupEnter="addItemsOtherContact"
							>
							</s-text>
						</v-col>
					</v-row>
					<v-row>
						<v-col class="s-col-form" cols="12" lg="12" md="12" sm="12" xs="12">
							<v-data-table
								v-model="selectedOtherContact"
								:headers="headerOtherContact"
								:items="itemsOtherContact"
								item-key=""
							>
								<template v-slot:item.accion="{ item }">
									<v-btn
										@click="deleteItemOtherContact(item)"
										block
										x-small
										color="error"
									>
										<v-icon x-small>fas fa-trash-alt</v-icon>
									</v-btn>
								</template>
							</v-data-table>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>

	</div>
</template>

<script>
	import SselectClientSap from '@/components/Sale/SselectClientSap.vue';
	import _sExtCustomerContactService from "@/services/ExternalTrade/ExtCustomerContactService.js";
	import _sExtCustomerContactEmailService from "@/services/ExternalTrade/ExtCustomerContactEmailService.js";
	import _sExtCustomerContactNotificationService from "@/services/ExternalTrade/ExtCustomerContactNotificationService.js";

	export default {
		components: {
			SselectClientSap,
		},

		props: {
			CustomerContact: null,
			isEdit: null
		},

		data() {
			return {
				objCustomer: {},
				headers: [
					{text: "ID", value: "line"},
					{text: "Puerto", value: "CcnDestinationPort"},
					{text: "Nombre", value: "CcnName"},
					{text: "Dirección", value: "CcnAddress"},
					{text: "Nombre Contacto", value: "CcnNameContact"},
					{text: "Email Contacto", value: "CcnEmailContact"},
					{text: "Acción", value: "accion"},
				],
				headerOtherContact: [
					{text: "Email", value: "CceEmail"},
					{text: "Acción", value: "accion", width:"200"},
				],
				dialogNotifier: false,
				dialogOtherContact: false,
				customerClient: {},
				fileIsf: [],
				selectedOtherContact: [],
				itemsOtherContact: [],
				itemsDeleteOtherContact: [],
				emailOtherContact: "",
				itemNotification: {},
				itemsNotification: [],
				itemsDeleteNotification: [],
				CctCompanyName: "",
				CctID: 0,
			}
		},

		watch: {
			objCustomer() {
				this.CctCompanyName = this.objCustomer.CardName;
			},

			isEdit()
			{
				this.getDataEdit();
			}
		},

		methods: {
			save() {
				if(this.CustomerContact == null){
					this.customerClient.CctCustomerID = this.objCustomer.CardCode;
				}

				if(this.isEdit){
					this.itemsOtherContact = [
						...this.itemsOtherContact,
						...this.itemsDeleteOtherContact
					]

					this.itemsNotification = [
						...this.itemsNotification,
						...this.itemsDeleteNotification
					]
				}

				this.customerClient.ExtCustomerContactEmails = this.itemsOtherContact;
				this.customerClient.ExtCustomerContactNotifications = this.itemsNotification;
				console.log('savee ', this.customerClient);

				_sExtCustomerContactService.save(this.customerClient, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.$fun.alert("Guardado Correctamente", "success");
						this.closeCustomerContactAdd();
						this.customerClient = [];
						this.itemsOtherContact = [];
						this.itemsNotification = [];
					}
				})
			},

			closeCustomerContactAdd()
			{
				this.$emit('closeCustomerContactAdd');
			},

			addItemsOtherContact()
			{
				let obj = {
					CctID: this.CctID,
					CceEmail: this.emailOtherContact,
					UsrCreateID: this.$fun.getUserID(),
					UsrUpdateID: this.$fun.getUserID(),
					SecStatus: 1
				}

				this.itemsOtherContact.push(obj);
				this.emailOtherContact = "";
			},

			deleteItemOtherContact(item)
			{
				item.SecStatus = 0;
				this.itemsDeleteOtherContact.push(item);
				this.itemsOtherContact = this.itemsOtherContact.filter( element => {
					return item.CceEmail != element.CceEmail;
				}
				);
				
			},

			addItemNotification()
			{
				let line = this.itemsNotification.length + 1;
				this.itemNotification.line = line;
				this.itemNotification.CctID = this.CctID;
				this.itemNotification.UsrCreateID = this.$fun.getUserID();
				this.itemNotification.UsrUpdateID = this.$fun.getUserID();
				this.itemNotification.SecStatus = 1;
				this.itemsNotification.push(this.itemNotification);
			},

			deleteItemNotification(item)
			{
				item.SecStatus = 0;
				this.itemsDeleteNotification.push(item);
				this.itemsNotification = this.itemsNotification.filter(element => {
					return element.CcnTax != item.CcnTax
				})
			},

			getDataInitialOtherContact()
			{
				_sExtCustomerContactEmailService.list(this.CctID, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.itemsOtherContact = resp.data;
					}
				})
			},

			getDataInitialNotification()
			{
				_sExtCustomerContactNotificationService.list(this.CctID, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.itemsNotification = resp.data;
					}
				})
			},

			getDataEdit()
			{
				if(this.isEdit){
					this.customerClient = this.CustomerContact;
					this.CctCompanyName = this.CustomerContact.CardName;
					this.customerClient.CctCustomerID = this.CustomerContact.CctCustomerID;
					console.log('this.customerClient ', this.customerClient);
					this.CctID = this.CustomerContact.CctID;
					this.getDataInitialOtherContact();
					this.getDataInitialNotification();
				}
				else{
					this.customerClient = [];
					this.itemsOtherContact = [];
					this.itemsNotification = [];
				}
			}
		},

		created () {
			this.getDataEdit();
		},
	}
</script>

import Service from '../Service';

const resource = "extcustomercontactnotification/";

export default {

    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },

    list(CctID, requestID) {
        return Service.post(resource + "list", {}, {
            params: { requestID: requestID, CctID: CctID },
        });
    }

}